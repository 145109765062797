import logo from './logo.png';
import logoBG from './logoBG.png';
import classStyle from './App.module.scss';

function App() {
    return (
        <div className={classStyle.app}>
            <div className={classStyle.header}>
                <div className={classStyle.headerBar}>
                    <img className={classStyle.logo} src={logo} />
                </div>
                <img className={classStyle.logoBG} src={logoBG} />
            </div>
            <div className={classStyle.contentView}>
                <div className={classStyle.content}>
                    <span className={classStyle.title}>THE COMPANY PROFILE</span>
                    <p className={classStyle.text}>
                        <span>LINKFUL PARTNER SOFTWARE COMPANY, INC.</span> is a corporation duly
                        organized and existing under and by virtue of the laws of the Philippines.
                        It was incorporated on the 16th day of November 2011 at the Securities and
                        Exchange Commission with Company Registration No. CS201120145. It formerly
                        held its principal office at 20/F Yuchengco Tower, RCBC Plaza, 6819 Ayala
                        Avenue, Makati City 1200. Currently, its new principal office address is at
                        37th Floor LKG Tower, 6801 Ayala Avenue, Bel-Air, Makati City.
                    </p>
                    <p className={classStyle.text}>
                        <span>LINKFUL PARTNER SOFTWARE COMPANY, INC. </span>is a corporation that
                        primarily engaged in the business of software development and production and
                        thereafter, markets, sells, or distributes the products. Consequently,
                        Linkful Partner Software Company Inc. likewise perform supplementary
                        services which may include, but not limited to leasing, repair, licensing,
                        and maintenance of hardware components relating to IT and other
                        telecommunication products and services. Further, planning, design, and
                        project management of IT and telecommunications – related services are also
                        provided.
                    </p>
                    <p className={classStyle.text}>
                        Moreover,
                        <span> LINKFUL PARTNER SOFTWARE COMPANY, INC. </span>
                        has a diversified business objective which include purchase and sell of real
                        and personal property; hold, lease, let, mortgage, encumber, or deal with
                        them but without necessarily owning a land.
                    </p>
                </div>
                <div className={classStyle.content}>
                    <span className={classStyle.title}>BUSINESS PRINCIPLES</span>
                    <p className={classStyle.text}>
                        <span className={classStyle.comText}>
                            LINKFUL PARTNER SOFTWARE COMPANY, INC.
                        </span>
                        <span> has the following business principles:</span>
                        <div className={classStyle.textList}>
                            <div className={classStyle.textListItem}>
                                • Service to customers must be the paramount consideration
                            </div>
                            <div className={classStyle.textListItem}>
                                • Turn ideas into profitable products
                            </div>
                            <div className={classStyle.textListItem}>
                                • Observe accountability and responsibility in all business dealings
                            </div>
                            <div className={classStyle.textListItem}>
                                • Regard customers as the core of its business
                            </div>
                            <div className={classStyle.textListItem}>
                                • Implement lawful and responsible employment practices
                            </div>
                        </div>
                    </p>
                </div>
                <div className={classStyle.contentList}>
                    <span className={classStyle.title}>MISSION, VISION AND VALUE STATEMENT</span>
                    <div className={classStyle.list}>
                        <div>
                            <div className={classStyle.listTitle}>Mission Statement:</div>
                            <div className={classStyle.listText}>
                                To provide the most accurate and innovative products and services to
                                all customers fitting to their businesses.
                            </div>
                        </div>
                        <div>
                            <div className={classStyle.listTitle}>Vision:</div>
                            <div className={classStyle.listText}>
                                To be the leader in the software development industry.
                            </div>
                        </div>
                        <div>
                            <div className={classStyle.listTitle}>Values:</div>
                            <div className={classStyle.listText}>
                                To uphold integrity and excellence in all its business dealings.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className={classStyle.footer}>
                <div className={classStyle.footerLeft}></div>
                <div className={classStyle.footerText}>
                    Copyright © 2011-2022 LINKFUL PARTNER SOFTWARE COMPANY, INC. All rights
                    reserved.
                </div>
            </footer>
        </div>
    );
}

export default App;
